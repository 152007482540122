const { config, library } = require('@fortawesome/fontawesome-svg-core');

import {
  faAngleDown as faAngleDownLight,
  faAngleRight as faAngleRightLight,
  faCalendarAlt as faCalendarAltLight,
  faClock as faClockLight,
  faDownload as faDownloadLight,
  faEllipsis as faEllipsisLight,
  faEnvelope as faEnvelopeLight,
  faEquals as faEqualsLight,
  faHeart as faHeartLight,
  faHomeLg as faHomeLgLight,
  faLocationDot as faLocationDotLight,
  faNotEqual as faNotEqualLight,
  faPhoneRotary as faPhoneRotaryLight,
  faPrint as faPrintLight,
  faRecycle as faRecycleLight,
  faSearch as faSearchLight,
  faShippingFast as faShippingFastLight,
  faSlidersSimple as faSliderSimpleLight,
  faTruck as faTruckLight,
  faWrench as faWrenchLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleLeft as faAngleLeftRegular,
  faAngleRight as faAngleRightRegular,
  faBars as faBarsRegular,
  faCartPlus as faCartPlusRegular,
  faCartShopping as faCartShoppingRegular,
  faCheckCircle as faCheckCircleRegular,
  faCheck as faCheckRegular,
  faEraser as faEraserRegular,
  faFileArrowDown as faFileArrowDownRegular,
  faHeart as faHeartRegular,
  faInfoCircle as faInfoCircleRegular,
  faPipe as faPipeRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faTelescope as faTelescopeRegular,
  faTimes as faTimesRegular,
  faXmark as faXmarkRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckCircle as faCheckCircleSolid,
  faCheck as faCheckSolid,
  faChevronDown as faChevronDownSolid,
  faChevronUp as faChevronUpSolid,
  faCircleXmark as faCircleXmarkSolid,
  faHeart as faHeartSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faPlus as faPlusSolid,
  faStar as faStarSolid,
  faXmark as faXmarkSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { faPipe as faPipeThin } from '@fortawesome/pro-thin-svg-icons';

library.add(
  // Solids
  faCheckCircleSolid,
  faCheckSolid,
  faChevronDownSolid,
  faChevronUpSolid,
  faCircleXmarkSolid,
  faHeartSolid,
  faPlusSolid,
  faStarSolid,
  faMagnifyingGlassSolid,
  faXmarkSolid,

  // Regulars
  faAngleLeftRegular,
  faAngleRightRegular,
  faBarsRegular,
  faCartPlusRegular,
  faCartShoppingRegular,
  faCheckCircleRegular,
  faCheckRegular,
  faEraserRegular,
  faHeartRegular,
  faInfoCircleRegular,
  faPipeRegular,
  faSpinnerThirdRegular,
  faTimesRegular,
  faXmarkRegular,
  faPipeRegular,
  faTelescopeRegular,
  faFileArrowDownRegular,

  // Lights
  faAngleDownLight,
  faAngleRightLight,
  faCalendarAltLight,
  faClockLight,
  faDownloadLight,
  faEqualsLight,
  faHeartLight,
  faHomeLgLight,
  faLocationDotLight,
  faNotEqualLight,
  faPhoneRotaryLight,
  faPrintLight,
  faRecycleLight,
  faShippingFastLight,
  faSliderSimpleLight,
  faTruckLight,
  faWrenchLight,
  faEnvelopeLight,
  faEllipsisLight,
  faSearchLight,
  faAngleDownLight,

  // Thins
  faPipeThin,
);

type Solid = {
  name:
    | 'star'
    | 'circle-xmark'
    | 'check-circle'
    | 'heart'
    | 'chevron-down'
    | 'chevron-up'
    | 'check'
    | 'plus'
    | 'magnifying-glass'
    | 'xmark';
  styling: 'fas';
};

type Regular = {
  name:
    | 'angle-left'
    | 'angle-right'
    | 'bars'
    | 'cart-plus'
    | 'cart-shopping'
    | 'check-circle'
    | 'check'
    | 'eraser'
    | 'heart'
    | 'info-circle'
    | 'pipe'
    | 'spinner-third'
    | 'times'
    | 'xmark'
    | 'bars'
    | 'spinner-third'
    | 'pipe'
    | 'telescope'
    | 'file-arrow-down';
  styling: 'far';
};

type Light = {
  name:
    | 'angle-down'
    | 'angle-right'
    | 'calendar-alt'
    | 'clock'
    | 'download'
    | 'ellipsis'
    | 'envelope'
    | 'equals'
    | 'heart'
    | 'home-lg'
    | 'location-dot'
    | 'not-equal'
    | 'phone-rotary'
    | 'print'
    | 'recycle'
    | 'search'
    | 'shipping-fast'
    | 'sliders-simple'
    | 'truck'
    | 'wrench';
  styling: 'fal';
};

type Thin = {
  name: 'pipe';
  styling: 'fat';
};

export type Custom = {
  name:
    | 'user'
    | 'phone-rotary'
    | 'info-circle'
    | 'clock'
    | 'map-marker-alt'
    | 'youtube-play'
    | 'store'
    | 'trash'
    | 'eye'
    | 'eye-slash'
    | 'plus-circle'
    | 'times-circle';
  styling: 'custom';
};

export type IconLookup = Solid | Regular | Light | Thin | Custom;

config.autoAddCss = false;
config.showMissingIcons = true;
