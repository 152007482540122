import { isWebview } from 'constants/environment';
import { pushKaminoBeacon } from 'features/kamino/connector';
import { useCactConsentGroupAccepted } from './useCactConsentGroupAccepted';

export const useKaminoTracking = () => {
  const consentAccepted = useCactConsentGroupAccepted('TARGETING');
  const trackingAllowed = consentAccepted && !isWebview;
  const _pushKaminoBeacon = trackingAllowed ? pushKaminoBeacon : undefined;
  return { pushKaminoBeacon: _pushKaminoBeacon, userHasSufficientConsent: trackingAllowed };
};
