import config from '../config';
import { ANALYTIC_EVENTS } from '../constants/analytics';
import { PAYMENT_METHODS } from '../constants/checkout';
import { createBasketProductArrayWithQuantities, getProductDatalayerCategoryPath } from './analyticsUtil';
import { groupEntries } from './orderUtil';

/**
 * Function which returns the gift card product object
 * @param {string} id - the gift card id
 * @param {number} price - the gift card price
 * @param {boolean} withQuantity - the withQuantity flag
 * @returns {object} the gift card product object
 * @deprecated
 */
function createGiftCardProduct(id, price, withQuantity = true) {
  return {
    brand: 'Giftcard',
    carrier: 'T',
    category: 'Gift cards',
    id,
    name: 'krefel Gift Card',
    price,
    quantity: withQuantity ? 1 : undefined,
    variant: 'atp_message_nostock_temp',
  };
}

export const createProductInfoObject = (
  name,
  code,
  price,
  brand,
  categories,
  atp,
  carrier,
  quantity,
  position,
  categoryList,
  asString = true
) => {
  const productInfoObject = {
    brand: brand?.name,
    carrier,
    category: getProductDatalayerCategoryPath(categories),
    id: code,
    list: categoryList,
    name,
    position,
    price: price?.value || price,
    quantity: quantity || 1,
    variant: atp?.stockMessage,
  };

  if (!asString) {
    return productInfoObject;
  }

  return JSON.stringify(productInfoObject);
};

export function createProductImpressionObject(product, position, categoryList, asString = true) {
  /* Search list */
  let list;
  if (categoryList) {
    list = categoryList;
  } else {
    list = product?.categories?.length > 0 ? product.categories[0].name : undefined;
  }

  const ProductImpressionObject = {
    brand: product?.brand?.name || product?.brand,
    carrier: product?.carrier ? product.carrier : undefined,
    category: product?.categories ?  getProductDatalayerCategoryPath(product.categories) : undefined,
    id: product?.code ? product.code : undefined,
    list,
    name: product?.name ? product.name : undefined,
    position,
    price: product?.price?.value || product?.priceValue,
    variant: product?.atp?.stockMessage || product?.stockMessage,
  };

  if (!asString) {
    return ProductImpressionObject;
  }
  return JSON.stringify(ProductImpressionObject);
}

/**
 * Function which creates the CMS product impression object
 * @param {Array} products - the cms product list
 * @param {string} pageName - the current page name
 * @returns {object} the CMS product impression object
 */
export const createCMSProductImpression = (products = [], pageName) => {
  if (products?.length === 0) return null;

  const CMSProductList = products?.map((product, index) =>
    createProductImpressionObject(product, index, pageName, false)
  );

  if (CMSProductList?.length > 0) {
    return {
      ecommerce: {
        currencyCode: 'EUR',
        impressions: CMSProductList,
      },
      event: ANALYTIC_EVENTS.PRODUCT_IMPRESSION,
    };
  }
  return null;
};

/**
 * Function which creates the gift card event object
 * @param {object} giftCard - the gift card object
 * @param {object} giftCardDetails - the gift card details
 * @returns {object} the gtm gift card event object
 */
export function getGiftCardEventObject(giftCard, giftCardDetails, isEmbedded = false) {
  const product = createGiftCardProduct(giftCard.code, Number(giftCardDetails.amount));
  return {
    ecommerce: {
      add: {
        products: [product],
      },
      currencyCode: 'EUR',
      giftcard: {
        actionField: {
          isAppEvent: isEmbedded,
        },
      },
    },
    event: ANALYTIC_EVENTS.ADD_TO_CART,
  };
}

/**
 * Function which creates the criteo home page impression object.
 * @returns {object} the criteo home page impression object.
 */
export const createCriteoHomePageImpression = (userEmail) => ({
  crto: {
    email: userEmail || '',
  },
  event: 'crto_homepage',
});

/**
 * Function which sets the localized criteo account number to the datalayer
 * @param {string} locale - The current locale.
 * @returns {undefined}
 */
export const setCriteoAccountNumber = (locale) => {
  const gtmData = {
    crto: {
      account: config?.criteo?.account?.[locale],
    },
    event: 'setAccount',
  };

  global?.dataLayer?.push(gtmData);
};

/**
 * Function which returns the onOrder tracking object
 * @param {Array} products - the products array
 * @param {string} deliveryMethod - the delivery method
 * @returns {object} the onOrder tracking object
 */
export function createCartTrackingObject(products = [], deliveryMethod = "", isEmbedded = false) {
  if (products && products.length > 0 && deliveryMethod) {
    return {
      ecommerce: {
        checkout: {
          actionField: {
            deliveryMethod,
            isAppEvent: isEmbedded,
            step: '1',
          },
          products,
        },
      },
      event: ANALYTIC_EVENTS.CHECKOUT,
    };
  }
  return null;
}

/**
 * the create payment tracking object function
 * @param {object} cart - the cart object
 * @param {object} paymentMethod - the payment method object
 * @param {boolean} hasGiftCards - the has gift cards flag
 * @return {object} the payment tracking object
 */
export function createPaymentTrackingObject(cart, paymentMethod, hasGiftCards = false, isEmbedded = false) {
  if (cart && cart.code && cart.entries && paymentMethod && paymentMethod.name) {
    const products = createBasketProductArrayWithQuantities(cart.entries, cart.productOnOrderCart);

    let paymentMode;
    if (hasGiftCards && paymentMethod.code === PAYMENT_METHODS.GIFTCARD) {
      paymentMode = paymentMethod.name;
    } else if (hasGiftCards && paymentMethod.code !== PAYMENT_METHODS.GIFTCARD) {
      paymentMode = `${paymentMethod.name}, Gift Card`;
    } else {
      paymentMode = paymentMethod.name;
    }
    return {
      ecommerce: {
        checkout: {
          actionField: {
            action: 'checkout',
            id: cart && cart.code ? cart.code : undefined,
            isAppEvent: isEmbedded,
            paymentMethod: paymentMode,
            step: '3',
          },
          products,
        },
      },
      event: ANALYTIC_EVENTS.CHECKOUT,
    };
  }
  return null;
}

/**
 * Function which creates the sales force add to cart object
 * @param {Array} entries - the cart entries
 * @param {object=} order - the order object
 * @returns {object} the sales force add to cart object
 */
export function createSalesForceAddToCartObject(entries, order) {
  const groupedEntries = groupEntries(entries) || [];
  const cartItems = [];

  const orderNumber = order && order.code;
  const shipping = order && order.deliveryCost && order.deliveryCost.value;
  const discount = order && order.totalDiscounts && order.totalDiscounts.value;

  if (groupedEntries && groupedEntries.length > 0) {
    groupedEntries.forEach((entry) => {
      const item = entry && entry.product && entry.product.code;
      const quantity = entry && entry.quantity;
      const price = entry && entry.basePrice && entry.basePrice.value;
      cartItems.push({ item, price, quantity });
    });
  }

  return {
    cart: cartItems,
    discount,
    order_number: orderNumber,
    shipping,
  };
}
