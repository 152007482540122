import { CMSLeaderBoard } from 'types/CMS';
import { CategoryContentTile } from 'types/Category';
import { BillboardPlacement } from 'types/Kamino';
import { Product } from 'types/Product';
import config from '../config';

const { platform } = config;

const capitalizedPlatform = platform.charAt(0).toUpperCase() + platform.slice(1);

/**
 * The product reference types.
 * @type {{ACCESSORIES: string, SIMILAR: string}}
 */
export const REFERENCE_TYPES = {
  ACCESSORIES: 'ACCESSORIES',
  SIMILAR: 'SIMILAR',
} as const;

/**
 * The product review rating typwa
 * @type {{USER_EXPERIENCE: string, DESIGN: string,PRICE_QUALITY: string, FUNCTIONALITY: string, KREFEL_SERVICE: string}}
 */
export const RATING_TYPES = {
  DESIGN: 'designRating',
  FUNCTIONALITY: 'functionalityRating',
  KREFEL_SERVICE: 'krefelServiceRating',
  PRICE_QUALITY: 'qualityRating',
  USER_EXPERIENCE: 'userExperienceRating',
};

/**
 * The product content provider types
 * @type {{FLIX: string, CNET: string}}
 */
export const CONTENT_PROVIDERS = {
  CNET: 'CNET',
  FLIX: 'FLIX',
  ICE_CAT_LIVE: 'ICECATLIVE',
  ICE_CAT_STORIES: 'ICECATSTORIES',
  ISITE: 'ISITE',
  LOAD_BEE: 'LOADBEE',
  WEBCOLLAGE: 'WEBCOLLAGE',
};

/**
 * The product carrier types
 * @type {{K: string, T: string}}
 */
export const PRODUCT_CARRIER = {
  K: 'K',
  T: 'T',
};
export type ProductCarrier = (typeof PRODUCT_CARRIER)[keyof typeof PRODUCT_CARRIER];

export const CLASSIFICATION_VALUES = {
  EMPTY: 'empty',
  FALSE: 'false',
  TRUE: 'true',
};

export const CLASSIFICATION_NAMES = {
  GENERAL: 'general',
  REVIEWS: 'reviews',
};

export const PRODUCT_STOCK_MESSAGE = {
  EXPOMODEL_ORDER: 'atp_message_pickup',
  NO_STOCK_TEMP: 'atp_message_nostock_temp',
  ON_ORDER: 'atp_message_order',
  PICKUP_K: 'atp_message_pickup_k',
  PICKUP_T: 'atp_message_pickup_t',
  PRE_ORDER: 'atp_message_preorder',
  STOCK: 'atp_message_stock',
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  YOUTUBE: 'youtube',
};

export const MEDIA_POSITION_TYPE = {
  OVERLAY: 'overlay',
  SLIDER: 'slider',
} as const;
export type MediaPositionType = (typeof MEDIA_POSITION_TYPE)[keyof typeof MEDIA_POSITION_TYPE];

export const PROMOTION_TYPE = {
  CASH_BACK: 'cash back',
  CASHBACK: 'Cashback',
  FREE_PRODUCT: 'FreeProduct',
  GENERIC: 'Generic',
  PROMO: 'promotion',
} as const;

export type PromotionType = (typeof PROMOTION_TYPE)[keyof typeof PROMOTION_TYPE];

export const PRODUCT_PARTNERS = {
  BPOST: 'Bpost',
  DEFAULT: capitalizedPlatform,
  HIFI: 'Hifi',
  KREFEL: 'Krefel',
  TONES: 'Tones',
};

export const PLP_CONTENT_TYPES = {
  BILLBOARD: 'BILLBOARD',
  CONTENT_TILE: 'CONTENT_TILE',
  LEADER_BOARD: 'LEADER_BOARD',
  PRODUCT: 'PRODUCT',
  PROMOTED_BRANDS: 'PROMOTED_BRANDS',
} as const;

export type PLPContentType = (typeof PLP_CONTENT_TYPES)[keyof typeof PLP_CONTENT_TYPES];

type PlpContentTile = CategoryContentTile & {
  type: Extract<PLPContentType, 'CONTENT_TILE'>;
};

type PlpLeaderBoard = {
  leaderBoards: CMSLeaderBoard[];
  type: Extract<PLPContentType, 'LEADER_BOARD'>;
};

type PlpPromotedBrands = {
  type: Extract<PLPContentType, 'PROMOTED_BRANDS'>;
};

type PlpProduct = Product & {
  type: Extract<PLPContentType, 'PRODUCT'>;
};

type PlpBillboard = BillboardPlacement & {
  type: Extract<PLPContentType, 'BILLBOARD'>;
};

export type PlpSearchResult = PlpContentTile | PlpLeaderBoard | PlpPromotedBrands | PlpProduct | PlpBillboard;

export const isPlpProduct = (item: PlpSearchResult): item is PlpProduct => item.type === PLP_CONTENT_TYPES.PRODUCT;

export const isPlpContentTile = (item: PlpSearchResult): item is PlpContentTile =>
  item.type === PLP_CONTENT_TYPES.CONTENT_TILE;

export const isPlpLeaderBoard = (item: PlpSearchResult): item is PlpLeaderBoard =>
  item.type === PLP_CONTENT_TYPES.LEADER_BOARD;

export const isPlpPromotedBrands = (item: PlpSearchResult): item is PlpPromotedBrands =>
  item.type === PLP_CONTENT_TYPES.PROMOTED_BRANDS;

export const isPlpBillboard = (item: PlpSearchResult): item is PlpBillboard =>
  item.type === PLP_CONTENT_TYPES.BILLBOARD;

export const USP_TYPES = {
  PARTNER: 'partner',
  PLATFORM: 'platform',
  SIMPLE: 'simple',
  TAXI: 'taxi',
};

export const SIGNING_DISPLAY_TYPES = {
  IMAGE: 'IMAGE',
  LABEL: 'LABEL',
} as const;
export type SigningDisplayType = (typeof SIGNING_DISPLAY_TYPES)[keyof typeof SIGNING_DISPLAY_TYPES];

export const PRODUCT_FIELDS_TYPES = {
  BASIC: 'BASIC',
  FULL: 'FULL',
  PDP: 'PDP',
} as const;
export type ProductFieldType = (typeof PRODUCT_FIELDS_TYPES)[keyof typeof PRODUCT_FIELDS_TYPES];

export const SCROLL_SPY_ITEMS = {
  ACCESSORIES: 'product-accessories',
  BUNDLES: 'product-bundles',
  COMPARE: 'product-compare-list',
  DESCRIPTION: 'product-description',
  REVIEWS: 'product-reviews',
} as const;
export type ScrollSpyType = (typeof SCROLL_SPY_ITEMS)[keyof typeof SCROLL_SPY_ITEMS];
