import axios from 'axios';
import { KAMINO_API_BASE_HOST } from 'constants/kamino';

export const KaminoApiClient = axios.create({
  baseURL: KAMINO_API_BASE_HOST,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 3000,
});

// kaminoApiClient.interceptors.request.use((config) => {
//   const customerId = useUserStore?.getState()?.user?.customerId;
//   if (customerId) {
//     // eslint-disable-next-line no-param-reassign
//     config.data = { ...config.data, customerId };
//   }
//   const locale = useUIStore?.getState()?.locale;
//   if (locale) {
//     // eslint-disable-next-line no-param-reassign
//     config.data = { ...config.data, locale };
//   }
//   return config;
// });

// kaminoApiClient.interceptors.response.use((response) => {
//   const criteoPageUid = response.data?.['page-uid'];
//   document?.body.setAttribute('data-criteo-page-uid', criteoPageUid);
//   return response;
// });
