export const ATP_MESSAGES = {
  BUTTON_STOCK: 'atp_button_stock',
  STOCK_AFTER_TOMORROW: 'atp_message_stock_day_after_tomorrow',
  STOCK_DELIVERY_DATE: 'atp_message_stock_delivery_date',
  STOCK_TOMORROW: 'atp_message_stock_tomorrow',
  TEMP_NO_STOCK: 'atp_message_nostock_temp',
};

export const DELIVERY_MOMENTS = {
  OVERMORROW: 'overmorrow',
  TOMORROW: 'tomorrow',
};

export const STOCK_MESSAGES = {
  EXPRESS: 'atp_message_express',
  ORDER: 'atp_message_order',
  PERM_NO_STOCK: 'atp_message_nostock_perm',
  PICKUP: 'atp_message_pickup',
  SEVEN_DAYS: 'atp_message_7days',
  STOCK: 'atp_message_stock',
  TEMP_NO_STOCK: 'atp_message_nostock_temp',
};
