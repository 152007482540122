import { CountryCode } from 'constants/store';
import { PostalCode } from 'types/PostalCode';

export const formatPostalCode = (cityName?: string, postalCode?: string | number, countryCode?: CountryCode) => {
  if (!cityName || !postalCode) {
    return '';
  }

  if (countryCode === 'LU') {
    return `L-${postalCode} - ${cityName}`;
  }

  return `${postalCode} - ${cityName}`;
};

export const destructurePostalCode = (formattedLocation?: string): PostalCode | undefined => {
  if (!formattedLocation) {
    return undefined;
  }

  const normalizeSpaceChars = formattedLocation?.toString().replace(/\u00A0/g, ' ');
  const locationObject = normalizeSpaceChars?.split(' - ');

  return {
    name: locationObject[1]?.trim(),
    postalCode: locationObject[0]?.trim(),
  };
};
