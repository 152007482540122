export const EXCEPTION_TYPES = {
  CartError: 'CartError',
  SavvyRateLimitError: 'SavvyRateLimitError',
};

export const EXCEPTION_REASONS = {
  notFound: 'notFound',
};

export const SAVVY_ERROR_CODES = {
  CARD_EXPIRED: '60',
  CARD_MERCHANT_MISMATCH_OR_CARD_RESTRICTED: '20',
  INVALID_FOR_STAFF_DISCOUNT: 'INVALID_FOR_STAFF_DISCOUNT',
  RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  ZERO_BALANCE: '1000',
};

export const USER_EXCEPTIONS = {
  CANNOT_FIND_USER: 'cannot find user with uid',
};

export const CHECKOUT_EXCEPTIONS = {
  ABORT: 'abort',
};
