import { apiClient } from 'helpers/ApiClient';
import { useAuthStore } from 'store/auth';
import Cookies from 'universal-cookie';
import { setDataFromReduxAction } from 'utils/customerFeedbackUtil';
import { convertToIso } from 'utils/dateUtil';
import { destructurePostalCode } from 'utils/postalCodeUtil';
import { getErrorMessageFromAction } from 'utils/reduxUtil';
import config from '../../config';
import { EXCEPTION_REASONS, EXCEPTION_TYPES } from '../../constants/exception';
import { log } from '../../utils/loggerUtil';
import {
  GET_DELIVERY_METHODS_SUCCESS,
  REMOVE_PAYMENT_ADDRESS_SUCCESS,
  REMOVE_PAYMENT_METHOD_SUCCESS,
  SUBMIT_DELIVERY_ADDRESS_SUCCESS,
  SUBMIT_DELIVERY_METHOD_SUCCESS,
  SUBMIT_GUEST_LOGIN_EMAIL_SUCCESS,
  SUBMIT_PAYMENT_METHOD_SUCCESS,
} from './checkout';
import {
  ADD_CONFIGURATION_TO_CART_SUCCESS,
  REMOVE_CONFIGURATION,
  REMOVE_CONFIGURATION_FAIL,
  REMOVE_CONFIGURATION_SUCCESS,
} from './configurator';
import { SUBMIT_PARTNER_DELIVERY_DATE_SUCCESS } from './partner';

const { platform } = config;

/**
 * Action types
 */
export const GET_CART = `${platform}/cart/GET_CART`;
export const GET_CART_SUCCESS = `${platform}/cart/GET_CART_SUCCESS`;
export const GET_CART_FAIL = `${platform}/cart/GET_CART_FAIL`;
export const CREATE_CART = `${platform}/cart/CREATE_CART`;
export const CREATE_CART_SUCCESS = `${platform}/cart/CREATE_CART_SUCCESS`;
export const CREATE_CART_FAIL = `${platform}/cart/CREATE_CART_FAIL`;
export const ADD_PRODUCT = `${platform}/cart/ADD_PRODUCT`;
export const ADD_PRODUCT_SUCCESS = `${platform}/cart/ADD_PRODUCT_SUCCESS`;
export const ADD_PRODUCT_FAIL = `${platform}/cart/ADD_PRODUCT_FAIL`;
export const DELETE_ENTRY = `${platform}/cart/DELETE_ENTRY`;
export const DELETE_ENTRY_SUCCESS = `${platform}/cart/DELETE_ENTRY_SUCCESS`;
export const DELETE_ENTRY_FAIL = `${platform}/cart/DELETE_ENTRY_FAIL`;
export const DELETE_PRODUCT = `${platform}/cart/DELETE_PRODUCT`;
export const DELETE_PRODUCT_SUCCESS = `${platform}/cart/DELETE_PRODUCT_SUCCESS`;
export const DELETE_PRODUCT_FAIL = `${platform}/cart/DELETE_PRODUCT_FAIL`;
export const UPDATE_ENTRY_PRODUCT_WARRANTY = `${platform}/cart/UPDATE_ENTRY_PRODUCT_WARRANTY`;
export const UPDATE_ENTRY_PRODUCT_WARRANTY_SUCCESS = `${platform}/cart/UPDATE_ENTRY_PRODUCT_WARRANTY_SUCCESS`;
export const UPDATE_ENTRY_PRODUCT_WARRANTY_FAIL = `${platform}/cart/UPDATE_ENTRY_PRODUCT_WARRANTY_FAIL`;
export const UPDATE_ENTRY_PRODUCT_SERVICE = `${platform}/cart/UPDATE_ENTRY_PRODUCT_SERVICE`;
export const UPDATE_ENTRY_PRODUCT_SERVICE_SUCCESS = `${platform}/cart/UPDATE_ENTRY_PRODUCT_SERVICE_SUCCESS`;
export const UPDATE_ENTRY_PRODUCT_SERVICE_FAIL = `${platform}/cart/UPDATE_ENTRY_PRODUCT_SERVICE_FAIL`;
export const CLEAR_CART = `${platform}/cart/CLEAR_CART`;
export const ADD_VOUCHER = `${platform}/cart/ADD_VOUCHER`;
export const ADD_VOUCHER_SUCCESS = `${platform}/cart/ADD_VOUCHER_SUCCESS`;
export const ADD_VOUCHER_FAIL = `${platform}/cart/ADD_VOUCHER_FAIL`;
export const REMOVE_VOUCHER = `${platform}/cart/REMOVE_VOUCHER`;
export const REMOVE_VOUCHER_SUCCESS = `${platform}/cart/REMOVE_VOUCHER_SUCCESS`;
export const REMOVE_VOUCHER_FAIL = `${platform}/cart/REMOVE_VOUCHER_FAIL`;
export const ADD_E_VOUCHER = `${platform}/cart/ADD_E_VOUCHER`;
export const ADD_E_VOUCHER_SUCCESS = `${platform}/cart/ADD_E_VOUCHER_SUCCESS`;
export const ADD_E_VOUCHER_FAIL = `${platform}/cart/ADD_E_VOUCHER_FAIL`;
export const GET_ABANDONED_CART = `${platform}/cart/GET_ABANDONED_CART`;
export const GET_ABANDONED_CART_SUCCESS = `${platform}/cart/GET_ABANDONED_CART_SUCCESS`;
export const GET_ABANDONED_CART_FAIL = `${platform}/cart/GET_ABANDONED_CART_FAIL`;
export const SET_CART_LOCATION = `${platform}/cart/GET_ABANDONED_CART_FAIL`;
export const SET_CART_LOCATION_SUCCESS = `${platform}/cart/SET_CART_LOCATION_SUCCESS`;
export const SET_CART_LOCATION_FAIL = `${platform}/cart/SET_CART_LOCATION_FAIL`;
export const ADD_BUNDLE = `${platform}/cart/ADD_BUNDLE`;
export const ADD_BUNDLE_SUCCESS = `${platform}/cart/ADD_BUNDLE_SUCCESS`;
export const ADD_BUNDLE_FAIL = `${platform}/cart/ADD_BUNDLE_FAIL`;
export const UPDATE_DONATION_TO_CART = `${platform}/cart/UPDATE_DONATION_TO_CART`;
export const UPDATE_DONATION_TO_CART_SUCCESS = `${platform}/cart/UPDATE_DONATION_TO_CART_SUCCESS`;
export const UPDATE_DONATION_TO_CART_FAIL = `${platform}/cart/UPDATE_DONATION_TO_CART_FAIL`;
export const UPDATE_CONFIGURATION_SERVICE = `${platform}/cart/UPDATE_CONFIGURATION_SERVICE`;
export const UPDATE_CONFIGURATION_SERVICE_SUCCESS = `${platform}/cart/UPDATE_CONFIGURATION_SERVICE_SUCCESS`;
export const UPDATE_CONFIGURATION_SERVICE_FAIL = `${platform}/cart/UPDATE_CONFIGURATION_SERVICE_FAIL`;
export const PRINT_CART = `${platform}/cart/PRINT_CART`;
export const PRINT_CART_SUCCESS = `${platform}/cart/PRINT_CART_SUCCESS`;
export const PRINT_CART_FAIL = `${platform}/cart/PRINT_CART_FAIL`;

/**
 * Adds a product to the cart.
 */
export const addProduct = (
  productCode,
  isAnonymous = false,
  anonymousCartId,
  giftCardDetails,
  potentialPromotions,
  hasSelectedExtraWarranty = false
) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient({
        method: "POST",
        params: {
          code: productCode,
          extendedWarranty: hasSelectedExtraWarranty,
          fields: 'FULL',
          giftCardMessage: giftCardDetails?.message,
          giftCardValue: giftCardDetails?.amount,
        },
        url: `/users/${userId}/carts/${cartId}/entries`
      }),
    types: [ADD_PRODUCT, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_FAIL],
  };
};

/**
 * Function which adds an e-voucher to the cart.
 */
export const addVoucherToCart = (isAnonymous = false, anonymousCartId, eVoucherDetails, eVoucherATCQuantity) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  const deliveryDate =
    eVoucherDetails && eVoucherDetails.deliveryDate
      ? new Date(eVoucherDetails.deliveryDate)
      : null;

  const params = {
    code: eVoucherDetails && eVoucherDetails.design && eVoucherDetails.design.code,
    fields: 'FULL',
    message: eVoucherDetails && eVoucherDetails.message,
    quantity: eVoucherATCQuantity,
    rcptMail: eVoucherDetails && eVoucherDetails.receiverEmail,
    rcptName: eVoucherDetails && eVoucherDetails.receiverName,
    senderMail: eVoucherDetails && eVoucherDetails.senderEmail,
    senderName: eVoucherDetails && eVoucherDetails.senderName,
    value: eVoucherDetails && eVoucherDetails.amount,
  };

  if (deliveryDate) {
    params.deliveryDate = convertToIso(deliveryDate);
  }

  return {
    promise: () =>
      apiClient({ method: "POST", params, url: `/users/${userId}/carts/${cartId}/entries/evoucher` }),
    types: [ADD_E_VOUCHER, ADD_E_VOUCHER_SUCCESS, ADD_E_VOUCHER_FAIL],
  };
};

export const updateCartDonation = (donationCode, selected, price, isAnonymous = false, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () => {
      const params = {
        fields: 'FULL',
        selected,
      };

      if (selected) {
        params.price = price;
      }

      return apiClient({ method: "PUT", params, url: `/users/${userId}/carts/${cartId}/donation/${donationCode}` })
    },
    types: [UPDATE_DONATION_TO_CART, UPDATE_DONATION_TO_CART_SUCCESS, UPDATE_DONATION_TO_CART_FAIL],
  };
};

/**
 * Updates the quantity of an entry in the cart.
 */
export const updateEntryWarranty = (entryCode, warrantyFlag, isAnonymous = false, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient({
        method: "PUT",
        params: {
          extendedWarranty: warrantyFlag,
          fields: 'FULL',
        },
        url: `/users/${userId}/carts/${cartId}/entries/${entryCode}`,
      }),
    types: [UPDATE_ENTRY_PRODUCT_WARRANTY, UPDATE_ENTRY_PRODUCT_WARRANTY_SUCCESS, UPDATE_ENTRY_PRODUCT_WARRANTY_FAIL],
  };
};

/**
 * Removes an entry from the cart.
 */
export const removeEntry = (entry, isAnonymous = false, anonymousCartId) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () => apiClient({ method: "DELETE", params: { fields: "FULL" }, url: `/users/${userId}/carts/${cartId}/entries/${entry.entryNumber}` }),
    types: [DELETE_ENTRY, DELETE_ENTRY_SUCCESS, DELETE_ENTRY_FAIL],
  };
};

/**
 * Removes the entries of a specific product from the cart.
 *
 * @param {object} product - The product that the entries of need to be removed.
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id from the cookie.
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} The remove action.
 */
export function removeProduct(product, isAnonymous = false, anonymousCartId = undefined) {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient({ method: "DELETE", url: `/users/${userId}/carts/${cartId}/entries/product/${product.code}?fields=FULL` }),
    types: [DELETE_PRODUCT, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_FAIL],
  };
}

/**
 * Updates the service of an entry
 *
 * @param {object} entry - The code of the product that needs to be added.
 * @param {object} service - The service to update.
 * @param {boolean} value - The new value of the service.
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id from the cookie.
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} The update service action.
 */
export function updateEntryProductService(entry, service, value, isAnonymous = false, anonymousCartId = undefined) {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient({
        method: "PUT", params: {
          fields: 'FULL',
          selected: value,
        },
        url: `/users/${userId}/carts/${cartId}/entries/${entry.entryNumber}/service/${service.code}`
      }),
    types: [UPDATE_ENTRY_PRODUCT_SERVICE, UPDATE_ENTRY_PRODUCT_SERVICE_SUCCESS, UPDATE_ENTRY_PRODUCT_SERVICE_FAIL],
  };
}

/**
 * Retrieves the abandoned cart action.
 *
 * @param {string} token - the abandoned cart token.
 * @returns {{types: string[], promise: (function(*): *)}} the abandoned cart action.
 */
export function getAbandonedCart(token) {
  return {
    promise: () =>
      apiClient({
        params: {
          fields: 'FULL',
        }, url: `/abandonedcart/${token}`,
      }),
    types: [GET_ABANDONED_CART, GET_ABANDONED_CART_SUCCESS, GET_ABANDONED_CART_FAIL],
  };
}

/**
 * Retrieves the cart action.
 * @returns {{types: [*,*,*], promise: (function(): Promise)}} The get cart action.
 */
export function getCart(isAnonymous = false, anonymousCartId, cartGuid) {
  if (isAnonymous && !anonymousCartId && !cartGuid) {
    return createAndSaveCart(isAnonymous);
  }

  if (cartGuid) {
    return {
      promise: () => apiClient({ url: `/cart/guid/${cartGuid}?fields=FULL` }),
      types: [GET_CART, GET_CART_SUCCESS, GET_CART_FAIL],
    };
  }

  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () => apiClient({ url: `/users/${userId}/carts/${cartId}?fields=FULL` }),
    types: [GET_CART, GET_CART_SUCCESS, GET_CART_FAIL],
  };
}

/**
 * Create a new cart action.
 * If a anonymous cart is is supplied, the anonymous cart will be transferred t the logged in user.
 *
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string=} mergeAnonymousCartId - The anonymous cart id that should be transferred to
 * the logged in user.
 * @returns {{types: [*,*,*], promise: (function(): Promise)}} The get cart action.
 */
export function createCart(isAnonymous, mergeAnonymousCartId) {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const mergeCartId = !isAnonymous && mergeAnonymousCartId ? `&oldCartId=${mergeAnonymousCartId} ` : '';

  return {
    promise: () => apiClient({ method: "POST", url: `/users/${userId}/carts?fields=FULL${mergeCartId}` }),
    types: [CREATE_CART, CREATE_CART_SUCCESS, CREATE_CART_FAIL],
  };
}

/**
 * Creates a cart and saves it to a cookie of the user is anonymous.
 *
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @returns {function(*): Promise} The create and save cart redux thunk action.
 * @private
 */
export const createAndSaveCart = (isAnonymous) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(createCart(isAnonymous))
      .then((result) => {
        if (isAnonymous) {
          new Cookies().set(config.cart.key, result.data.guid, {
            ...config.cookie,
            maxAge: config.cart.expirationTime,
          });
        }

        resolve(result);
      })
      .catch((error) => {
        log('CART', `Could not create new cart.`);

        reject(error);
      });
  });

/**
 * Checks if the current user (anonymous or not) has a cart.
 *
 * For an anonymous user, the cart id from the cookie will be used to fetch the cart.
 * If that fails, a new cart will be created and saved to the cookie.
 *
 * For a authenticated user, the active cart will be retrieved from the api.
 * If there is no active cart, a new one will be created.
 *
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id from the cookie.
 * @param {boolean} createIfNotExists - Flag to indicate if a new cart should be created if
 * none exists.
 * @returns {function(*=): Promise} The check cart thunk action.
 */
export function checkCart(isAnonymous, anonymousCartId, createIfNotExists = true) {
  return (dispatch, getState, cookies) =>
    new Promise((resolve, reject) => {
      if (!isAnonymous || anonymousCartId) {
        dispatch(getCart(isAnonymous, anonymousCartId))
          .then((result) => resolve(result.data))
          .catch(({ response }) => {
            log('CART', `No cart found with id ${anonymousCartId}.`);
            if (createIfNotExists) {
              dispatch(createAndSaveCart(isAnonymous))
                .then((cartResult) => resolve(cartResult))
                .catch((cartError) => reject(cartError));
            }

            const error = response?.data?.errors && response.data.errors[0];
            if (error && error.type === EXCEPTION_TYPES.CartError && error.reason === EXCEPTION_REASONS.notFound) {
              cookies.remove(config.cart.key, config.cookie);
            }

            resolve(error);
          });
      } else if (createIfNotExists) {
        dispatch(createAndSaveCart(isAnonymous))
          .then((cartResult) => resolve(cartResult.data))
          .catch((cartError) => reject(cartError));
      }
    });
}

export const addBundleToCart = ({ anonymousCartId, baseProduct, bundleCode, isAnonymous, productCodes }) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  const selectedProducts = productCodes?.join(',');

  return {
    promise: () =>
      apiClient({
        method: "POST",
        params: {
          accessories: selectedProducts,
          baseProduct: baseProduct?.code,
          bundleCode,
          fields: 'FULL',
        },
        url: `/users/${userId}/carts/${cartId}/bundle`
      }),
    types: [ADD_BUNDLE, ADD_BUNDLE_SUCCESS, ADD_BUNDLE_FAIL],
  };
};

/**
 * Adds a product to the cart.
 *
 * @param {object} cart - The current cart object.
 * @param {object} product - The product to add.
 * @param {boolean} isAnonymous - Flag to indicate if the current user is anonymous.
 * @param {string} anonymousCartId - The anonymous cart id.
 * @param {object=} giftCardDetails - The gift card details object.
 * @param {object=} eVoucherDetails - The e-voucher object object.
 * @param {boolean} hasSelectedExtraWarranty - The flag that indicates of warranty is selected.
 * @returns {function(*): Promise} The add to cart redux thunk action.
 * @private
 */
// eslint-disable-next-line no-underscore-dangle
const _addProductToCart =
  (
    cart,
    product,
    isAnonymous,
    anonymousCartId,
    giftCardDetails,
    eVoucherDetails,
    potentialPromotions,
    hasSelectedExtraWarranty,
    productCodes,
    bundleCode,
    eVoucherATCQuantity
  ) =>
    (dispatch) =>
      new Promise((resolve, reject) => {
        if (productCodes?.length > 0 && !!bundleCode) {
          dispatch(addBundleToCart({ anonymousCartId, baseProduct: product, bundleCode, isAnonymous, productCodes }))
            .then((newCartResult) => resolve(newCartResult))
            .catch((addProductError) => {
              const cartId = (cart && cart.guid) || anonymousCartId;
              log('CART', `Could not add products to cart ${cartId} for product with code ${productCodes?.join(', ')}.`);

              reject(addProductError);
            });
        } else if (eVoucherDetails && Object.keys(eVoucherDetails).length > 0) {
          dispatch(addVoucherToCart(isAnonymous, anonymousCartId, eVoucherDetails, eVoucherATCQuantity))
            .then((newCartResult) => resolve(newCartResult))
            .catch((addProductError) => {
              const cartId = (cart && cart.guid) || anonymousCartId;
              log('CART', `Could not add product to cart ${cartId} for product with code ${product.code}.`);

              reject(addProductError);
            });
        } else {
          dispatch(
            addProduct(
              product.code,
              isAnonymous,
              anonymousCartId,
              giftCardDetails,
              potentialPromotions,
              hasSelectedExtraWarranty
            )
          )
            .then((newCartResult) => resolve(newCartResult))
            .catch((addProductError) => {
              const cartId = (cart && cart.guid) || anonymousCartId;

              log('CART', `Could not add product to cart ${cartId} for product with code ${product.code}.`);
              reject(addProductError);
            });
        }
      });

/**
 * Checks the current cart before adding the product to it.
 *
 * @param {object} cart - The current cart object.
 * @param {object} product - The product to add.
 * @param {boolean} isAnonymous - Flag to indicate if the current user is anonymous.
 * @param {object=} giftCardDetails - The gift card details object.
 * @param {object=} eVoucherDetails - The e-voucher details object.
 * @param {number=} retry - The retry count.
 * @returns {function(*=): Promise} The check cart and add product redux thunk action.
 * @param {boolean} hasSelectedExtraWarranty - The flag that indicates of warranty is selected.
 * @private
 */
const checkCartAndAddProduct =
  (
    cart,
    product,
    isAnonymous = false,
    giftCardDetails,
    eVoucherDetails,
    retry = 0,
    potentialPromotions,
    hasSelectedExtraWarranty,
    productCodes,
    bundleCode,
    eVoucherATCQuantity,
    onCartCreation,
  ) =>
    (dispatch, getState, cookies) =>
      new Promise((resolve, reject) => {
        const anonymousCartId = cookies.get(config.cart.key);

        // (is anonymous and has anonymous cart cookie) OR (logged in and has active cart)
        if ((isAnonymous && anonymousCartId) || (cart?.guid && !isAnonymous)) {
          dispatch(
            _addProductToCart(
              cart,
              product,
              isAnonymous,
              anonymousCartId,
              giftCardDetails,
              eVoucherDetails,
              potentialPromotions,
              hasSelectedExtraWarranty,
              productCodes,
              bundleCode,
              eVoucherATCQuantity
            )
          )
            .then((result) => {
              resolve(result);
            })
            .catch((response) => {
              const error = response?.data?.errors?.[0];
              if (
                error?.type === EXCEPTION_TYPES.CartError &&
                error?.reason === EXCEPTION_REASONS.notFound &&
                retry <= 1
              ) {
                cookies.remove(config.cart.key, config.cookie);
                dispatch(
                  checkCartAndAddProduct(
                    null,
                    product,
                    isAnonymous,
                    giftCardDetails,
                    eVoucherDetails,
                    retry + 1,
                    potentialPromotions,
                    hasSelectedExtraWarranty,
                    productCodes,
                    bundleCode,
                    eVoucherATCQuantity,
                    onCartCreation
                  )
                )
                  .then((newCartResult) => {
                    resolve(newCartResult);
                  })
                  .catch((addProductError) => {
                    log(
                      'CART',
                      `Could not add product to cart ${cart?.guid || anonymousCartId} for product with code ${product.code
                      }.`
                    );
                    reject(addProductError);
                  });
              } else {
                reject(error);
              }
            });
        } else {
          // is anonymous and has no cart cookie OR is logged in and has no cart in state
          dispatch(checkCart(isAnonymous, anonymousCartId))
            .then((cartResult) =>
              dispatch(
                _addProductToCart(
                  cart,
                  product,
                  isAnonymous,
                  cartResult?.guid,
                  giftCardDetails,
                  eVoucherDetails,
                  potentialPromotions,
                  hasSelectedExtraWarranty,
                  productCodes,
                  bundleCode,
                  eVoucherATCQuantity
                )
              )
                .then((newCartResult) => {
                  if (newCartResult.data) {
                    onCartCreation({ cart: newCartResult.data });
                  }
                  resolve(newCartResult);
                })
                .catch((addProductError) => {
                  log('CART', `Could not add product to cart ${cart?.guid} for product with code ${product.code}.`);
                  reject(addProductError);
                })
            )
            .catch((checkCartError) => {
              log('CART', `Could not fetch cart to add product with code ${product?.code} to.`);
              reject(checkCartError);
            });
        }
      });

export const addProductToCart =
  (
    cart,
    product,
    isAnonymous = false,
    giftCardDetails,
    eVoucherDetails,
    hasSelectedExtraWarranty,
    productCodes,
    bundleCode,
    eVoucherATCQuantity,
    onCartCreation
  ) =>
    (dispatch, getState, cookies) => {
      // Check auth
      const authCookie = cookies.get(config.oauth.key);
      let promotions = product && product.potentialPromotions;

      if (product?.freeProductChoices?.length > 0) {
        promotions = [{ freeProductOptions: product.freeProductChoices }];
      }


      if (!authCookie) {
        return useAuthStore.getState().actions.authenticateAnonymous().then(() =>
          dispatch(
            checkCartAndAddProduct(
              cart,
              product,
              isAnonymous,
              giftCardDetails,
              eVoucherDetails,
              0,
              promotions,
              hasSelectedExtraWarranty,
              productCodes,
              bundleCode,
              eVoucherATCQuantity,
              onCartCreation
            )
          )
        );
      }
      // has token --> perform add to cart
      return dispatch(
        checkCartAndAddProduct(
          cart,
          product,
          isAnonymous,
          giftCardDetails,
          eVoucherDetails,
          0,
          promotions,
          hasSelectedExtraWarranty,
          productCodes,
          bundleCode,
          eVoucherATCQuantity,
          onCartCreation
        )
      );
    };

/**
 * Function which validates the given voucher code.
 * @param {string} voucherId - the voucher id
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {boolean} isAnonymous - the is anonymous flag
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}} the validate voucher code action
 */
export function validateVoucherCode(voucherId, anonymousCartId, isAnonymous = false) {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  return {
    promise: () =>
      apiClient({
        method: "POST",
        params: {
          fields: 'FULL',
          voucherId,
        },
        url: `/users/${userId}/carts/${cartId}/vouchers`,
      }),
    types: [ADD_VOUCHER, ADD_VOUCHER_SUCCESS, ADD_VOUCHER_FAIL],
  };
}

/**
 * Function which removes the given voucher code.
 * @param {string} voucherId - the voucher id
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {boolean} isAnonymous - the is anonymous flag
 * @returns {{types: [*,*,*], promise: (function(*): (*|Request))}} the remove voucher code action
 */
export function removeValidatedVoucherCode(voucherId, anonymousCartId, isAnonymous = false) {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';
  return {
    promise: () => apiClient({
      method: "DELETE",
      params: {
        fields: 'FULL',
        voucherId,
      },
      url: `/users/${userId}/carts/${cartId}/vouchers/${voucherId}`,
    }),
    types: [REMOVE_VOUCHER, REMOVE_VOUCHER_SUCCESS, REMOVE_VOUCHER_FAIL],
  };
}

/**
 * Clears the cart reducer.
 *
 * @return {{type: string}} The clear cart action.
 */
export function clearCart({ clearAnonymousCart = true } = {}) {
  return (dispatch, getState, cookies) => {
    if (clearAnonymousCart) {
      cookies.remove(config.cart.key, config.cookie);
    }

    return dispatch({
      type: CLEAR_CART,
    });
  };
}

/**
 * Function which sets the cart location
 * @param {string} location - the selected location
 * @param {boolean} anonymous - the anonymous flag
 * @param {string} anonymousCartId - the anonymous cart id
 * @param {string=} country - the selected country
 * @returns {{types: string[], apiClient: (function(*): *)}} the set cart location action
 */
export function setCartLocation(location, anonymous = false, anonymousCartId, country = 'BE') {
  const userId = anonymous ? 'anonymous' : 'current';
  const cartId = anonymous ? anonymousCartId : 'current';
  const currentLocation = destructurePostalCode(location);
  return {
    promise: () =>
      apiClient({
        method: "PUT",
        params: {
          country,
          fields: 'FULL',
          location: currentLocation.town,
          postalCode: currentLocation.postalCode,
        },
        url: `/users/${userId}/carts/${cartId}/postalcode`,
      }
      ),
    types: [SET_CART_LOCATION, SET_CART_LOCATION_SUCCESS, SET_CART_LOCATION_FAIL],
  };
}


/**
 * Updates the service of a configuration
 *
 * @param {string} configurationUid - The uid of the configuration that needs to be changed.
 * @param {object} serviceCode - The code from the service to update.
 * @param {boolean} value - The new value of the service.
 * @param {boolean} isAnonymous - Flag if the current user is anonymous or not.
 * @param {string} anonymousCartId - The anonymous cart id from the cookie.
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} The update service action.
 */
export const updateCartConfigurationService = ({
  anonymousCartId,
  configurationUid,
  isAnonymous = false,
  serviceCode,
  value,
}) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient({
        method: "PUT",
        params: {
          fields: 'FULL',
          selected: value,
        },
        url: `/users/${userId}/carts/${cartId}/configurator/${configurationUid}/service/${serviceCode}`,
      }
      ),
    types: [UPDATE_CONFIGURATION_SERVICE, UPDATE_CONFIGURATION_SERVICE_SUCCESS, UPDATE_CONFIGURATION_SERVICE_FAIL],
  };
};

export const printCart = ({ anonymousCartId, isAnonymous = false }) => {
  const userId = isAnonymous ? 'anonymous' : 'current';
  const cartId = isAnonymous ? anonymousCartId : 'current';

  return {
    promise: () =>
      apiClient({
        params: {
          fields: 'FULL',
        },
        url: `/users/${userId}/carts/${cartId}/download`,
      }),
    types: [PRINT_CART, PRINT_CART_SUCCESS, PRINT_CART_FAIL],
  };
};

/**
 * Reducer
 */
const initialState = {
  cart: null,
  cartError: null,
  cartFlyoverVisible: false,
  isFetchingCart: false,
  isInitializedCart: false,
  isUpdatingCart: false,
  selectedDeliverySlot: null,
  voucherError: null,
};

/**
 * The cart reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REMOVE_CONFIGURATION:
    case UPDATE_ENTRY_PRODUCT_SERVICE:
    case UPDATE_CONFIGURATION_SERVICE:
    case UPDATE_ENTRY_PRODUCT_WARRANTY:
    case ADD_PRODUCT:
    case ADD_BUNDLE:
    case DELETE_PRODUCT:
    case DELETE_ENTRY: {
      return {
        ...state,
        cartError: null,
        isUpdatingCart: true,
        voucherError: null,
      };
    }
    case REMOVE_VOUCHER:
    case ADD_E_VOUCHER:
    case ADD_VOUCHER: {
      return {
        ...state,
        isUpdatingCartSummary: true,
      };
    }
    case CREATE_CART:
    case GET_CART:
    case GET_ABANDONED_CART: {
      return {
        ...state,
        isFetchingCart: true,
        isInitializedCart: true,
      };
    }
    case ADD_PRODUCT_SUCCESS: {
      const cart = action.result.data && Object.keys(action.result.data).length > 0 ? action.result.data : null;
      return {
        ...state,
        cart,
        isFetchingCart: false,
        isUpdatingCart: false,
        isUpdatingCartSummary: false,
      };
    }
    case UPDATE_DONATION_TO_CART:
      return {
        ...state,
        isUpdatingCart: true,
      };
    case UPDATE_DONATION_TO_CART_SUCCESS:
    case GET_CART_SUCCESS:
    case GET_ABANDONED_CART_SUCCESS:
    case CREATE_CART_SUCCESS:
    case DELETE_ENTRY_SUCCESS:
    case DELETE_PRODUCT_SUCCESS:
    case UPDATE_ENTRY_PRODUCT_WARRANTY_SUCCESS:
    case UPDATE_ENTRY_PRODUCT_SERVICE_SUCCESS:
    case SUBMIT_DELIVERY_METHOD_SUCCESS:
    case SUBMIT_DELIVERY_ADDRESS_SUCCESS:
    case SUBMIT_PAYMENT_METHOD_SUCCESS:
    case REMOVE_PAYMENT_METHOD_SUCCESS:
    case REMOVE_PAYMENT_ADDRESS_SUCCESS:
    case ADD_VOUCHER_SUCCESS:
    case REMOVE_VOUCHER_SUCCESS:
    case SUBMIT_PARTNER_DELIVERY_DATE_SUCCESS:
    case ADD_E_VOUCHER_SUCCESS:
    case SET_CART_LOCATION_SUCCESS:
    case ADD_CONFIGURATION_TO_CART_SUCCESS:
    case REMOVE_CONFIGURATION_SUCCESS:
    case SUBMIT_GUEST_LOGIN_EMAIL_SUCCESS:
    case UPDATE_CONFIGURATION_SERVICE_SUCCESS:
      return {
        ...state,
        cart: action.result.data && Object.keys(action.result.data).length > 0 ? action.result.data : null,
        isFetchingCart: false,
        isUpdatingCart: false,
        isUpdatingCartSummary: false,
      };
    case ADD_BUNDLE_SUCCESS:
      return {
        ...state,
        cart: action.result.data && Object.keys(action.result.data).length > 0 ? action.result.data : null,
        isFetchingCart: false,
        isUpdatingCart: false,
        isUpdatingCartSummary: false,
      };
    case REMOVE_CONFIGURATION_FAIL:
    case GET_CART_FAIL:
    case GET_ABANDONED_CART_FAIL:
    case CREATE_CART_FAIL:
    case UPDATE_ENTRY_PRODUCT_WARRANTY_FAIL:
    case UPDATE_ENTRY_PRODUCT_SERVICE_FAIL:
    case DELETE_ENTRY_FAIL:
    case DELETE_PRODUCT_FAIL:
    case ADD_E_VOUCHER_FAIL:
    case ADD_PRODUCT_FAIL:
    case ADD_BUNDLE_FAIL:
    case UPDATE_CONFIGURATION_SERVICE_FAIL:
    case UPDATE_DONATION_TO_CART_FAIL: {
      const errors = action?.error?.response?.data?.errors || [];
      const cartError = errors && errors.length > 0 && errors[0];
      const cookies = new Cookies();
      const anonymousCartId = cookies.get(config.cart.key)
      if (cartError?.reason === "notFound" && cartError?.subject === anonymousCartId) {
        cookies.remove(config.cart.key, config.cookie)
      }

      switch (action.type) {
        case GET_CART_FAIL:
        case CREATE_CART_FAIL:
        case ADD_PRODUCT_FAIL:
        case DELETE_ENTRY_FAIL:
        case DELETE_PRODUCT_FAIL:
        case UPDATE_ENTRY_PRODUCT_WARRANTY_FAIL:
        case UPDATE_ENTRY_PRODUCT_SERVICE_FAIL:
        case ADD_E_VOUCHER_FAIL:
        case GET_ABANDONED_CART_FAIL: {
          setDataFromReduxAction(cartError?.message);
        }
      }

      return {
        ...state,
        cartError,
        isFetchingCart: false,
        isUpdatingCart: false,
      };
    }
    case CLEAR_CART: {
      return {
        ...state,
        cart: null,
      };
    }
    case ADD_VOUCHER_FAIL: {
      setDataFromReduxAction(getErrorMessageFromAction(action));
      return {
        ...state,
        isUpdatingCartSummary: false,
        voucherError:
          action?.error?.response?.data?.errors?.length > 0
            ? action.error.response.data.errors[0]
            : null,
      };
    }
    case GET_DELIVERY_METHODS_SUCCESS: {
      return {
        ...state,
        cart: {
          ...state.cart,
          selectedPostalCode: action && action.selectedPostalCode,
        },
      };
    }
    case REMOVE_VOUCHER_FAIL: {
      setDataFromReduxAction(getErrorMessageFromAction(action));
      break;
    }
    default:
      return state;
  }
}
