/**
 * The Redux user module containing the store related action, action types and reducer.
 */

import config from '../../config';
import { registerUserEmail } from '../../utils/salesforceUtil';
import { SOCIAL_TYPES } from '../../constants/oauth';
import { prefixUserCredentials } from '../../utils/authUtil';
import { apiClient } from 'helpers/ApiClient';
import { useAuthStore } from 'store/auth';

const { platform } = config;

/**
 * Action types
 */
export const REGISTER = `${platform}/user/REGISTER`;
export const REGISTER_SUCCESS = `${platform}/user/REGISTER_SUCCESS`;
export const REGISTER_FAIL = `${platform}/user/REGISTER_FAIL`;
export const VALIDATE_EMAIL = `${platform}/user/VALIDATE_EMAIL`;
export const VALIDATE_EMAIL_SUCCESS = `${platform}/user/VALIDATE_EMAIL_SUCCESS`;
export const VALIDATE_EMAIL_FAIL = `${platform}/user/VALIDATE_EMAIL_FAIL`;
export const CHECK_EXISTING_USER = `${platform}/user/CHECK_EXISTING_USER`;
export const CHECK_EXISTING_USER_SUCCESS = `${platform}/user/CHECK_EXISTING_USER_SUCCESS`;
export const CHECK_EXISTING_USER_FAIL = `${platform}/user/CHECK_EXISTING_USER_FAIL`;
export const REQUEST_NEW_PASSWORD = `${platform}/user/REQUEST_NEW_PASSWORD`;
export const REQUEST_NEW_PASSWORD_SUCCESS = `${platform}/user/REQUEST_NEW_PASSWORD_SUCCESS`;
export const REQUEST_NEW_PASSWORD_FAIL = `${platform}/user/REQUEST_NEW_PASSWORD_FAIL`;
export const RESET_PASSWORD = `${platform}/user/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${platform}/user/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${platform}/user/RESET_PASSWORD_FAIL`;
export const VALIDATE_VAT_NUMBER = `${platform}/customer/VALIDATE_VAT_NUMBER`;
export const VALIDATE_VAT_NUMBER_SUCCESS = `${platform}/customer/VALIDATE_VAT_NUMBER_SUCCESS`;
export const VALIDATE_VAT_NUMBER_FAIL = `${platform}/customer/VALIDATE_VAT_NUMBER_FAIL`;
export const REGISTER_AS_GUEST = `${platform}/customer/REGISTER_AS_GUEST`;
export const REGISTER_AS_GUEST_SUCCESS = `${platform}/customer/REGISTER_AS_GUEST_SUCCESS`;
export const REGISTER_AS_GUEST_FAIL = `${platform}/customer/REGISTER_AS_GUEST_FAIL`;
export const VALIDATE_RECAPTCHA_USER = `${platform}/customer/VALIDATE_RECAPTCHA_USER`;
export const VALIDATE_RECAPTCHA_USER_SUCCESS = `${platform}/customer/VALIDATE_RECAPTCHA_USER_SUCCESS`;
export const VALIDATE_RECAPTCHA_USER_FAIL = `${platform}/customer/VALIDATE_RECAPTCHA_USER_FAIL`;

/**
 * Actions
 */

/**
 * Registers the user.
 *
 * @param {*} user - Values for the new user.
 * @param {boolean} isFacebook - Flag to indicate if the registration is a facebook registration.
 * @param {boolean} isGoogle - Flag to indicate if the registration is a google registration.
 * @param {boolean} isApple - Flag to indicate if the registration is a apple registration.
 * @returns {{types: [*,*,*], promise: (function(*): (Request|*))}} The register action.
 * @private
 */
function _register(user, isFacebook, isGoogle, isApple) {
  let social = '';

  if (isFacebook) {
    social = '?facebook=true';
  } else if (isGoogle) {
    social = '?google=true';
  } else if (isApple) {
    social = '?apple=true';
  }

  return {
    promise: () =>
      apiClient({
        data: user,
        method: 'POST',
        url: `/users/create${social}`,
      }),
    types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
  };
}

/**
 * Checks if the current user is authenticated and registers the user with provided data.
 *
 * @param {object} user - The data for registering the new user.
 * @param {boolean} isFacebook - Flag to indicate if the registration is a facebook registration.
 * @param {boolean} isGoogle - Flag to indicate if the registration is a google registration.
 * @param {boolean} isApple - Flag to indicate if the registration is a apple registration.
 * @returns {function(*=)} The redux thunk for registering a new user.
 */
export function register(user, isFacebook, isGoogle, isApple) {
  return (dispatch, getState, cookies) => {
    const authCookie = cookies && cookies[config?.oauth?.key];

    if (!authCookie) {
      return new Promise((resolve, reject) => {
        useAuthStore
          .getState()
          .actions.authenticateAnonymous()
          .then(() => {
            dispatch(_register(prefixUserCredentials(user), isFacebook, isGoogle, isApple))
              .then((registerResult) => resolve(registerResult))
              .catch((registerError) => reject(registerError));
          })
          .catch((error) => reject(error));
      });
    }
    return dispatch(_register(user, isFacebook, isGoogle, isApple));
  };
}

/**
 * Function which registers the guest user after the anonymous checkout
 * @param {string} guid - the cart id
 * @param {string} password - the user's password
 * @return {{types: [*,*,*], promise: (function(*): (*|Request))}} The register as guest action
 */
export function registerAsGuest(guid, password) {
  return {
    promise: () =>
      apiClient({
        data: {
          password,
        },
        method: 'POST',
        params: {
          fields: 'FULL',
        },
        url: `/orders/convert/${guid}`,
      }),
    types: [REGISTER_AS_GUEST, REGISTER_AS_GUEST_SUCCESS, REGISTER_AS_GUEST_FAIL],
  };
}

/**
 * Validates an email address with the backend.
 *
 * @param {string} email - The provided email address.
 * @returns {{types: [*,*,*], promise: (function(*))}} The email validation action.
 */
export function validateEmail(email) {
  const { email: prefixedEmail } = prefixUserCredentials({ email });
  return {
    promise: () => apiClient({ url: `/validation/email/${prefixedEmail}` }),
    types: [VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAIL],
  };
}

/**
 * Validates an vat number with the backend.
 *
 * @param {string} vatNumber - The provided vat number.
 * @returns {{types: [*,*,*], promise: (function(*))}} The email validation action.
 */
export function validateVatNumber(vatNumber) {
  return {
    promise: () => apiClient({ url: `/validation/vat/${vatNumber}` }),
    types: [VALIDATE_VAT_NUMBER, VALIDATE_VAT_NUMBER_SUCCESS, VALIDATE_VAT_NUMBER_FAIL],
  };
}

/**
 * Checks if a user with a specific email already exists.
 *
 * @param {string} email - The provided email address.
 * @returns {function} The check user exist action.
 */
export function checkExistingUser(data, type = SOCIAL_TYPES.EMAIL) {
  const endpoint = `${type}${type !== SOCIAL_TYPES.EMAIL ? 'Id' : ''}=${data}`;
  return {
    promise: () => apiClient({ url: `/exists?${endpoint}` }),
    types: [CHECK_EXISTING_USER, CHECK_EXISTING_USER_SUCCESS, CHECK_EXISTING_USER_FAIL],
  };
}

/**
 * Requests a new password.
 *
 * @param {string} email - The user email.
 * @returns {function} The requestNewPassword action.
 */
export function requestNewPassword(email) {
  const { email: prefixedEmail } = prefixUserCredentials({ email });
  registerUserEmail(prefixedEmail);
  const formattedEmail = encodeURIComponent(prefixedEmail);
  return {
    promise: () => apiClient({ method: 'POST', url: `/forgottenpasswordtokens?userId=${formattedEmail}` }),
    types: [REQUEST_NEW_PASSWORD, REQUEST_NEW_PASSWORD_SUCCESS, REQUEST_NEW_PASSWORD_FAIL],
  };
}

/**
 * Resets the users password.
 *
 * @param {string} password - The new password.
 * @param {string} token -- The password reset token.
 * @returns {function} The resetPassword action.
 */
export function resetPassword(password, token) {
  return {
    promise: () =>
      apiClient({
        data: {
          password,
          passwordConfirmation: password,
          token,
        },
        method: 'PUT',
        url: `/forgottenpasswordtokens`,
      })
    ,
    types: [RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL],
  };
}



/**
 * Function which validates if the current user is not a bot
 * @param {string} token - the recaptcha token
 * @returns {{types: string[], promise: (function(*): *)}} The validate recaptcha user action
 */
export function validateRecaptchaUser(token) {
  return {
    promise: () =>
      apiClient({
        params: {
          response: token,
        },
        url: `/google/recaptcha`,
      }),
    types: [VALIDATE_RECAPTCHA_USER, VALIDATE_RECAPTCHA_USER_SUCCESS, VALIDATE_RECAPTCHA_USER_FAIL],
  };
}

/**
 * Reducer
 */
const initialState = {
  isRegistering: false,
  vatCompanyAddress: null,
};

/**
 * The user reducer.
 *
 * @param {Object} state The default or current state.
 * @param {Object} action The dispatched action.
 * @returns {Object} The updated state.
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REGISTER_AS_GUEST:
    case REGISTER: {
      return {
        ...state,
        isRegistering: true,
      };
    }
    case REGISTER_FAIL:
    case REGISTER_AS_GUEST_FAIL: {
      return {
        ...state,
        isRegistering: false,
      };
    }
    case VALIDATE_VAT_NUMBER: {
      return {
        ...state,
        isLoadingVatValidation: true,
      };
    }
    case VALIDATE_VAT_NUMBER_SUCCESS: {
      return {
        ...state,
        companyCountryCode: action?.result?.data?.data?.company?.countryCode,
        companyFormattedAddress: action?.result?.data?.data?.company?.formattedAddress,
        companyName: action?.result?.data?.data?.company?.companyName,
        isLoadingVatValidation: false,
        isVatValid: action?.result?.data?.data?.valid,
      };
    }
    case VALIDATE_VAT_NUMBER_FAIL: {
      return {
        ...state,
        isLoadingVatValidation: false,
        isVatValid: false,
      };
    }
    default:
      return state;
  }
}
