import { MILLISECONDS_IN_HOUR, MILLISECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND, TENSES, Tense } from 'constants/date';
import { BRAND_SPECIFIC_ISO_LOCALES } from 'constants/i18n';
import { Schedule } from 'types/Date';
import { RoundingMethod } from 'types/Math';
import { log } from './loggerUtil';

export const parseDate = (dateOrString: string | Date) => {
  if (typeof dateOrString !== 'string') {
    return dateOrString;
  }
  const dateObj = new Date(dateOrString);
  if (isNaN(dateObj.getTime())) {
    throw new Error(`Invalid date format: ${dateOrString} cannot be parsed`);
  }
  return dateObj;
};

export type FormatDateOptions = Intl.DateTimeFormatOptions & { locale: string };

type FormatDateParams = {
  date?: Date | string;
  options: FormatDateOptions;
};

export const formatDate = ({ date, options }: FormatDateParams) => {
  if (!date) return;

  try {
    const { locale, ...formatDateOptions } = options;
    const parsedDate = parseDate(date);
    const isoLocale = BRAND_SPECIFIC_ISO_LOCALES[locale] ?? locale;
    return new Intl.DateTimeFormat(isoLocale, formatDateOptions).format(parsedDate);
  } catch (error) {
    log('dateUtil - formatDate', 'Something went wrong', error);
  }
};

export function calculateDaysBetweenDates(firstDate: string | Date, secondDate: string | Date) {
  try {
    const first = parseDate(firstDate);
    const second = parseDate(secondDate);
    return Math.abs(first.getDay() - second.getDay());
  } catch (error) {
    log('dateUtil - calculateDaysBetweenDates', 'Something went wrong', error);
  }
}

/**
 * Converts the javascript ISO 8601 and removes the milliseconds portion (required for hybris OCC).
 *
 * @param {Date|string} date - Javascript date.
 * @returns {string} Formatted date as a string.
 */
export function convertToIso(date: Date | string) {
  return `${new Date(date).toISOString().split('.')[0]}Z`;
}

/**
 * Function which returns true if the delivery date is the next day
 * @param {Date | string} deliveryDate - the first available date
 * @param {Date | string} currentDate - the current date
 * @returns {boolean|string} true if the delivery date is the next day
 */
export const checkNextDayDelivery = (deliveryDate: Date | string, currentDate: Date | string) => {
  if (deliveryDate && currentDate) {
    const diffDays = new Date(deliveryDate).getDay() - new Date(currentDate).getDay();

    if (diffDays === 1) {
      return true;
    }
    if (diffDays === 0) {
      return 'nextHours';
    }
  }
  return false;
};

/**
 * Function which returns true/false when the current date is/isn't between the start and end date
 * @param {Date | string} currentDate - the current date
 * @param {Date | string} startDate - the start date
 * @param {Date | string} endDate - the end date
 * @returns {boolean} - returns the boolean flag
 */
export function checkDateBetweenDates(currentDate: Date | string, startDate: Date | string, endDate: Date | string) {
  const date = new Date(currentDate);
  const start = new Date(startDate);
  const end = new Date(endDate);

  return date.getTime() >= start.getTime() && date.getTime() <= end.getTime();
}

/**
 * Function which checks if two dates are the same
 * @param {Date | string} currentDate - the current date
 * @param {Date | string} comparedDate - the compared date
 * @returns {boolean} True if the dates are the same. False if not.
 */
export function areEqualDates(currentDate: Date | string, comparedDate: Date | string) {
  if (currentDate && comparedDate) {
    return calculateDaysBetweenDates(comparedDate, currentDate) === 0;
  }
  return false;
}

type CheckDateAvailableParams = {
  availableDates?: string[];
  selectedDate: Date | string;
};

export const CheckDateAvailable = ({ availableDates = [], selectedDate }: CheckDateAvailableParams) => {
  try {
    const date = parseDate(selectedDate);
    return availableDates.some((availableDate) => {
      const parsedAvailableDate = parseDate(availableDate);
      return date.toString() === parsedAvailableDate.toString();
    });
  } catch (error) {
    log('dateUtil - CheckDateAvailable', 'Something went wrong', error);
    return false;
  }
};

export function getHQSchedule(
  currentDate: Date | string,
  schedules: Schedule[] = [],
  locale: string,
): Schedule | undefined {
  const day = getISODay(new Date(currentDate));
  const currentDay = getISODay(new Date());
  const currentDayName = formatDate({ date: new Date(), options: { locale, weekday: 'long' } });
  const filteredDays = schedules.filter((scheduleItem) => scheduleItem.weekDay === currentDayName);
  const currentDaySchedule = filteredDays.length ? filteredDays[0] : undefined;

  if (schedules && schedules.length >= currentDay) {
    if (day === currentDay) {
      const currentSchedule = schedules[0];
      const packupHour = new Date(currentDate).getHours();
      if (
        currentSchedule &&
        packupHour &&
        currentSchedule.closingTime &&
        currentSchedule.closingTime.hour &&
        packupHour < currentSchedule.closingTime.hour
      ) {
        return { day: 'today', hours: schedules[0] };
      }
      return { day: 'tomorrow', hours: schedules[1] };
    }
    if (day === currentDay + 1) {
      return { day: 'tomorrow', hours: schedules[1] };
    }
    return { day: currentDate.toString(), hours: currentDaySchedule };
  }
}

/**
 * Formats a date to ISO 8601 format (YYYY-MM-DD).
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date in ISO 8601 format (YYYY-MM-DD).
 */
export const formatISODate = (date: Date) => new Date(date).toISOString().slice(0, 10);

/**
 * Calculates the min and max date by the given date.
 * @param {Tense} tense - The tense.
 * @returns {string} The min and max date by the given date .
 */
export const getMinMaxDateForDatePicker = (tense: Tense) => {
  const currentYear = new Date().getFullYear();

  const minYear = tense === TENSES.PAST ? currentYear - 100 : currentYear;
  const maxYear = tense === TENSES.PAST ? currentYear : currentYear + 100;

  const minDate = new Date(new Date().setFullYear(minYear));
  const maxDate = new Date(new Date().setFullYear(maxYear));

  return { maxDate, minDate };
};

export function addLeadingZeros(number: number, targetLength: number): string {
  const sign = number < 0 ? '-' : '';
  const output = Math.abs(number).toString().padStart(targetLength, '0');
  return sign + output;
}

/**
 * @name formatISO
 * @category Common Helpers
 * @summary Format the date according to the ISO 8601 standard (https://support.sas.com/documentation/cdl/en/lrdict/64316/HTML/default/viewer.htm#a003169814.htm).
 *
 * @description
 * Return the formatted date string in ISO 8601 format. Options may be passed to control the parts and notations of the date.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param date - The original date
 * @param options - An object with options.
 *
 * @returns The formatted date string (in loca.l time zone)
 *
 * @throws `date` must not be Invalid Date
 *
 * @example
 * // Represent 18 September 2019 in ISO 8601 format (local time zone is UTC):
 * const result = formatISO(new Date(2019, 8, 18, 19, 0, 52))
 * //=> '2019-09-18T19:00:52Z'
 *
 * @example
 * // Represent 18 September 2019 in ISO 8601, short format (local time zone is UTC):
 * const result = formatISO(new Date(2019, 8, 18, 19, 0, 52), { format: 'basic' })
 * //=> '20190918T190052'
 *
 * @example
 * // Represent 18 September 2019 in ISO 8601 format, date only:
 * const result = formatISO(new Date(2019, 8, 18, 19, 0, 52), { representation: 'date' })
 * //=> '2019-09-18'
 *
 * @example
 * // Represent 18 September 2019 in ISO 8601 format, time only (local time zone is UTC):
 * const result = formatISO(new Date(2019, 8, 18, 19, 0, 52), { representation: 'time' })
 * //=> '19:00:52Z'
 */
export const formatISO = (date: Date, options?: { format?: string; representation?: string }) => {
  const _date = new Date(date);

  if (isNaN(_date.getTime())) {
    throw new RangeError('Invalid time value');
  }

  const format = options?.format ?? 'extended';
  const representation = options?.representation ?? 'complete';

  let result = '';
  let tzOffset = '';

  const dateDelimiter = format === 'extended' ? '-' : '';
  const timeDelimiter = format === 'extended' ? ':' : '';

  // Representation is either 'date' or 'complete'
  if (representation !== 'time') {
    const day = addLeadingZeros(_date.getDate(), 2);
    const month = addLeadingZeros(_date.getMonth() + 1, 2);
    const year = addLeadingZeros(_date.getFullYear(), 4);

    // yyyyMMdd or yyyy-MM-dd.
    result = `${year}${dateDelimiter}${month}${dateDelimiter}${day}`;
  }

  // Representation is either 'time' or 'complete'
  if (representation !== 'date') {
    // Add the timezone.
    const offset = _date.getTimezoneOffset();

    if (offset !== 0) {
      const absoluteOffset = Math.abs(offset);
      const hourOffset = addLeadingZeros(Math.trunc(absoluteOffset / 60), 2);
      const minuteOffset = addLeadingZeros(absoluteOffset % 60, 2);
      // If less than 0, the sign is +, because it is ahead of time.
      const sign = offset < 0 ? '+' : '-';

      tzOffset = `${sign}${hourOffset}:${minuteOffset}`;
    } else {
      tzOffset = 'Z';
    }

    const hour = addLeadingZeros(_date.getHours(), 2);
    const minute = addLeadingZeros(_date.getMinutes(), 2);
    const second = addLeadingZeros(_date.getSeconds(), 2);

    // If there's also date, separate it with time with 'T'
    const separator = result === '' ? '' : 'T';

    // Creates a time string consisting of hour, minute, and second, separated by delimiters, if defined.
    const time = [hour, minute, second].join(timeDelimiter);

    // HHmmss or HH:mm:ss.
    result = `${result}${separator}${time}${tzOffset}`;
  }

  return result;
};

/**
 * @name millisecondsToHours
 * @category Conversion Helpers
 * @summary Convert milliseconds to hours.
 *
 * @description
 * Convert a number of milliseconds to a full number of hours.
 *
 * @param milliseconds - The number of milliseconds to be converted
 *
 * @returns The number of milliseconds converted in hours
 *
 * @example
 * // Convert 7200000 milliseconds to hours:
 * const result = millisecondsToHours(7200000)
 * //=> 2
 *
 * @example
 * // It uses floor rounding:
 * const result = millisecondsToHours(7199999)
 * //=> 1
 */
export function millisecondsToHours(milliseconds: number): number {
  const hours = milliseconds / MILLISECONDS_IN_HOUR;
  return Math.trunc(hours);
}

/**
 * @name millisecondsToMinutes
 * @category Conversion Helpers
 * @summary Convert milliseconds to minutes.
 *
 * @description
 * Convert a number of milliseconds to a full number of minutes.
 *
 * @param milliseconds - The number of milliseconds to be converted
 *
 * @returns The number of milliseconds converted in minutes
 *
 * @example
 * // Convert 60000 milliseconds to minutes:
 * const result = millisecondsToMinutes(60000)
 * //=> 1
 *
 * @example
 * // It uses floor rounding:
 * const result = millisecondsToMinutes(119999)
 * //=> 1
 */
export function millisecondsToMinutes(milliseconds: number): number {
  const minutes = milliseconds / MILLISECONDS_IN_MINUTE;
  return Math.trunc(minutes);
}

/**
 * @name millisecondsToSeconds
 * @category Conversion Helpers
 * @summary Convert milliseconds to seconds.
 *
 * @description
 * Convert a number of milliseconds to a full number of seconds.
 *
 * @param milliseconds - The number of milliseconds to be converted
 *
 * @returns The number of milliseconds converted in seconds
 *
 * @example
 * // Convert 1000 miliseconds to seconds:
 * const result = millisecondsToSeconds(1000)
 * //=> 1
 *
 * @example
 * // It uses floor rounding:
 * const result = millisecondsToSeconds(1999)
 * //=> 1
 */
export function millisecondsToSeconds(milliseconds: number): number {
  const seconds = milliseconds / MILLISECONDS_IN_SECOND;
  return Math.trunc(seconds);
}

/**
 * @name differenceInMilliseconds
 * @category Millisecond Helpers
 * @summary Get the number of milliseconds between the given dates.
 *
 * @description
 * Get the number of milliseconds between the given dates.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param dateLeft - The later date
 * @param dateRight - The earlier date
 *
 * @returns The number of milliseconds
 *
 * @example
 * // How many milliseconds are between
 * // 2 July 2014 12:30:20.600 and 2 July 2014 12:30:21.700?
 * const result = differenceInMilliseconds(
 *   new Date(2014, 6, 2, 12, 30, 21, 700),
 *   new Date(2014, 6, 2, 12, 30, 20, 600)
 * )
 * //=> 1100
 */
export function differenceInMilliseconds(dateLeft: Date | number | string, dateRight: Date | number | string): number {
  return +new Date(dateLeft) - +new Date(dateRight);
}

export function getRoundingMethod(method: RoundingMethod | undefined) {
  return (number: number) => {
    const round = method ? Math[method] : Math.trunc;
    const result = round(number);
    // Prevent negative zero
    return result === 0 ? 0 : result;
  };
}

/**
 * @name differenceInSeconds
 * @category Second Helpers
 * @summary Get the number of seconds between the given dates.
 *
 * @description
 * Get the number of seconds between the given dates.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param dateLeft - The later date
 * @param dateRight - The earlier date
 * @param options - An object with options.
 *
 * @returns The number of seconds
 *
 * @example
 * // How many seconds are between
 * // 2 July 2014 12:30:07.999 and 2 July 2014 12:30:20.000?
 * const result = differenceInSeconds(
 *   new Date(2014, 6, 2, 12, 30, 20, 0),
 *   new Date(2014, 6, 2, 12, 30, 7, 999)
 * )
 * //=> 12
 */
export function differenceInSeconds<DateType extends Date>(
  dateLeft: DateType | number | string,
  dateRight: DateType | number | string,
  options?: { roundingMethod: RoundingMethod },
): number {
  const diff = differenceInMilliseconds(dateLeft, dateRight) / 1000;
  return getRoundingMethod(options?.roundingMethod)(diff);
}

/**
 * @name isDate
 * @category Common Helpers
 * @summary Is the given value a date?
 *
 * @description
 * Returns true if the given value is an instance of Date. The function works for dates transferred across iframes.
 *
 * @param value - The value to check
 *
 * @returns True if the given value is a date
 *
 * @example
 * // For a valid date:
 * const result = isDate(new Date())
 * //=> true
 *
 * @example
 * // For an invalid date:
 * const result = isDate(new Date(NaN))
 * //=> true
 *
 * @example
 * // For some value:
 * const result = isDate('2014-02-31')
 * //=> false
 *
 * @example
 * // For an object:
 * const result = isDate({})
 * //=> false
 */
export function isDate(value: unknown): value is Date {
  return (
    value instanceof Date || (typeof value === 'object' && Object.prototype.toString.call(value) === '[object Date]')
  );
}

/**
 * @name isValid
 * @category Common Helpers
 * @summary Is the given date valid?
 *
 * @description
 * Returns false if argument is Invalid Date and true otherwise.
 * Invalid Date is a Date, whose time value is NaN.
 *
 * Time value of Date: http://es5.github.io/#x15.9.1.1
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param date - The date to check
 *
 * @returns The date is valid
 *
 * @example
 * // For the valid date:
 * const result = isValid(new Date(2014, 1, 31))
 * //=> true
 *
 * @example
 * // For the value, convertable into a date:
 * const result = isValid(1393804800000)
 * //=> true
 *
 * @example
 * // For the invalid date:
 * const result = isValid(new Date(''))
 * //=> false
 */
export function isValid(date: unknown): boolean {
  if (!isDate(date) && typeof date !== 'number') {
    return false;
  }
  const _date = new Date(date);
  return !isNaN(Number(_date));
}

/**
 * @name startOfDay
 * @category Day Helpers
 * @summary Return the start of a day for the given date.
 *
 * @description
 * Return the start of a day for the given date.
 * The result will be in the local timezone.
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param date - The original date
 *
 * @returns The start of a day
 *
 * @example
 * // The start of a day for 2 September 2014 11:55:00:
 * const result = startOfDay(new Date(2014, 8, 2, 11, 55, 0))
 * //=> Tue Sep 02 2014 00:00:00
 */
export function startOfDay(date: Date | number | string) {
  const _date = new Date(date);
  _date.setHours(0, 0, 0, 0);
  return _date;
}

/**
 * @name isSameDay
 * @category Day Helpers
 * @summary Are the given dates in the same day (and year and month)?
 *
 * @description
 * Are the given dates in the same day (and year and month)?
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param dateLeft - The first date to check
 * @param dateRight - The second date to check

 * @returns The dates are in the same day (and year and month)
 *
 * @example
 * // Are 4 September 06:00:00 and 4 September 18:00:00 in the same day?
 * const result = isSameDay(new Date(2014, 8, 4, 6, 0), new Date(2014, 8, 4, 18, 0))
 * //=> true
 *
 * @example
 * // Are 4 September and 4 October in the same day?
 * const result = isSameDay(new Date(2014, 8, 4), new Date(2014, 9, 4))
 * //=> false
 *
 * @example
 * // Are 4 September, 2014 and 4 September, 2015 in the same day?
 * const result = isSameDay(new Date(2014, 8, 4), new Date(2015, 8, 4))
 * //=> false
 */
export function isSameDay<DateType extends Date>(
  dateLeft: DateType | number | string,
  dateRight: DateType | number | string,
): boolean {
  const dateLeftStartOfDay = startOfDay(dateLeft);
  const dateRightStartOfDay = startOfDay(dateRight);

  return +dateLeftStartOfDay === +dateRightStartOfDay;
}

/**
 * @name isToday
 * @category Day Helpers
 * @summary Is the given date today?
 * @pure false
 *
 * @description
 * Is the given date today?
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param date - The date to check
 *
 * @returns The date is today
 *
 * @example
 * // If today is 6 October 2014, is 6 October 14:00:00 today?
 * const result = isToday(new Date(2014, 9, 6, 14, 0))
 * //=> true
 */
export function isToday<DateType extends Date>(date: DateType | number | string): boolean {
  return isSameDay(date, new Date());
}

/**
 * @name isTomorrow
 * @category Day Helpers
 * @summary Is the given date tomorrow?
 * @pure false
 *
 * @description
 * Is the given date tomorrow?
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param date - The date to check
 *
 * @returns The date is tomorrow
 *
 * @example
 * // If today is 6 October 2014, is 7 October 14:00:00 tomorrow?
 * const result = isTomorrow(new Date(2014, 9, 7, 14, 0))
 * //=> true
 */
export function isTomorrow<DateType extends Date>(date: DateType | number | string): boolean {
  return isSameDay(date, new Date().setDate(new Date().getDate() + 1));
}

/**
 * @name getISODay
 * @category Weekday Helpers
 * @summary Get the day of the ISO week of the given date.
 *
 * @description
 * Get the day of the ISO week of the given date,
 * which is 7 for Sunday, 1 for Monday etc.
 *
 * ISO week-numbering year: http://en.wikipedia.org/wiki/ISO_week_date
 *
 * @typeParam DateType - The `Date` type, the function operates on. Gets inferred from passed arguments.
 *
 * @param date - The given date
 *
 * @returns The day of ISO week
 *
 * @example
 * // Which day of the ISO week is 26 February 2012?
 * const result = getISODay(new Date(2012, 1, 26))
 * //=> 7
 */
export function getISODay<DateType extends Date>(date: DateType | number | string): number {
  const _date = new Date(date);
  let day = _date.getDay();

  if (day === 0) {
    day = 7;
  }

  return day;
}

export const validateDates = (dateToCheck: Date, dates: Date[]) => {
  if (!dates?.length) {
    return false;
  }

  const normalizedDate = new Date(dateToCheck);
  normalizedDate.setHours(0, 0, 0, 0);

  return dates.some((availableDate) => {
    const slotNormalizedDate = new Date(availableDate);
    slotNormalizedDate.setHours(0, 0, 0, 0);
    return slotNormalizedDate.getTime() === normalizedDate.getTime();
  });
};

export const handleDateDelimiter = (date: string) => date.replace(/-/g, '/');

// TODO remove formatISODate when all forms are refactored because of efficiency
// More efficient because it directly calls toISOString on the provided date without creating a new Date object. This makes it slightly more performant and cleaner.
export const formatISODateSplitByT = (date: Date) => date.toISOString().split('T')[0];
