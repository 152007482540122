import Script from 'next/script';

const EmarsysScript = () => {
  const merchantId = process.env.NEXT_PUBLIC_EMARSYS_MERCHANT_ID;

  if (!merchantId) {
    return null;
  }

  return (
    <Script id="emarsys-script" strategy="afterInteractive">
      {`
      var ScarabQueue = ScarabQueue || [];
      (function(id) {
      if (document.getElementById(id)) return;
      var js = document.createElement('script'); js.id = id;
      js.src = '//cdn.scarabresearch.com/js/${merchantId}/scarab-v2.js';
      var fs = document.getElementsByTagName('script')[0];
      fs.parentNode.insertBefore(js, fs);
      })('scarab-js-api');
    `}
    </Script>
  );
};

export default EmarsysScript;
