const KAMINO_API_BASE_HOST = process.env.NEXT_PUBLIC_KAMINO_DELIVERY_API_HOST ?? '';

const KAMINO_API_ENABLED = process.env.NEXT_PUBLIC_KAMINO_DELIVERY_API_ENABLED === 'true';

const KAMINO_RETAILER_ID = process.env.NEXT_PUBLIC_KAMINO_DELIVERY_API_RETAILER_ID ?? '';

const KAMINO_PAGE_TYPES = {
  CATEGORY: 'Category',
  CONFIRMATIONPAGE: 'ConfirmationPage',
  CUSTOMLIST: 'CustomList',
  HOMEPAGE: 'HomePage',
  PRODUCTPAGE: 'ProductPage',
  SEARCH: 'Search',
  SHOP: 'Shop',
  UNIVERSE: 'Universe',
} as const;
export type KaminoPageType = (typeof KAMINO_PAGE_TYPES)[keyof typeof KAMINO_PAGE_TYPES];

const SPONSORED_PRODUCTS_FIXED_POSITIONS = [2, 7, 8, 11];

export {
  KAMINO_API_BASE_HOST,
  KAMINO_API_ENABLED,
  KAMINO_PAGE_TYPES,
  KAMINO_RETAILER_ID,
  SPONSORED_PRODUCTS_FIXED_POSITIONS,
};
